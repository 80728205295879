// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const Rails = require("@rails/ujs")
const application = Application.start()

Rails.start()
window.Rails = Rails

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import jquery from 'jquery';
window.$ = window.jquery = jquery;
require("jquery")


require('bootstrap/dist/js/bootstrap.bundle.min.js')
require('data-confirm-modal')
require('chartkick').use(require("highcharts"))
require("chart.js")
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
require("select2/dist/js/i18n/ja")

// import 'moment/moment'
// import 'bootstrap4-datetimepicker/src/js/bootstrap-datetimepicker'
// import 'bootstrap4-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss'
// import 'moment/locale/ja'

require("@fullcalendar/core")
require("@fullcalendar/interaction")

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

require('jquery-datetimepicker/build/jquery.datetimepicker.full')
import 'jquery-datetimepicker/jquery.datetimepicker.css';

import "bootstrap"
import '../stylesheets/application'
import './bootstrap_custom.js'
import './module.js'
import './client.js'

import "swiper/css/swiper.min.css"
require('swiper/js/swiper.js')
require('dropzone')
import "dropzone/dist/dropzone.css";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import { Application } from "stimulus"
import Popover from "stimulus-popover"

application.register("popover", Popover)

// import datetimepicker from 'jquery-datetimepicker/jquery.datetimepicker'
// window.$ = datetimepicker

require("dayjs");
require("daterangepicker");
import "daterangepicker/daterangepicker.css"
