import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" , "add_moderator_modal", "send_email", "username" , "email", "role"]

  connect() {
    this.session_user_id = window.ig_analitics_params.user_id;
    this.element[this.identifier] = this;
  }

  add_moderator_modal_open() {
    let modal = this.application.getControllerForElementAndIdentifier(
      this.add_moderator_modalTarget,
      "modal"
    );
    modal.open();
  }

  add_moderator_modal_close() {
    let modal = this.application.getControllerForElementAndIdentifier(
      this.add_moderator_modalTarget,
      "modal"
    );
    modal.close();
  }


  delete_user(ele) {
    $.ajax({
      url: "/client/api/v1/users/",
      data: {user_id: ele.target.dataset.id},
      type: 'delete',
      success: (data) => {
        window.location.href = "/client/settings"
      },
      error: (data) => {
      },
    })
  }

  send_email(ele) {
    let [data, status] = ele.detail;
    let result = "";
    let noticeController = this.element.notice;
    try {
      result = JSON.parse(data.response);
    } catch {}
    if (result["errors"]) {
      noticeController.error(result["errors"]);
    }else {
      noticeController.success("招待を送信しました");
      this.add_moderator_modal_close();
    }
  }

  edit_moderator_form(ele) {
    ele.preventDefault();
    if( $(ele.target).hasClass("username_text") ){
      $(ele.target).next().show().focus();
      $(ele.target).hide();

    }else {
      $(ele.target).children(".username_text").hide();
      $(ele.target).children(".username_input").show().focus();
    }
  }
  
  username_change(ele) {
    let _this = this;
    let username = $(ele.target).prev().val();
    let old_username = $(ele.target).closest('div').prev('.username_text').text();
    $(ele.target).closest('div').prev('.username_text').text(username).show();
    $(ele.target).closest('div').hide();
    if (old_username == username)  return;

    $.ajax({
      url: "/client/api/v1/users/moderator_name_change",
      data: {user_id: ele.target.dataset.id, username: username},
      type: 'patch',
      success: (data) => {
        _this.element.notice.success("情報を更新しました")
      },
      error: (data) => {
        _this.element.notice.success("情報を更新が失敗しました")
      },
    }) 
  }

  save(ele) {
    let _this = this;
    let formData = new FormData();
    formData.append("name" , this.usernameTarget.value);
    formData.append("email" , this.emailTarget.value);

    this.roleTargets.forEach((ele) => {
      if ($(ele).val() != $(ele).data("role")){
        formData.append("user_moderators["+ $(ele).data("id") +"]", $(ele).val());
      }
    });

    $.ajax({
      url: "/client/api/v1/users/" + this.session_user_id,
      data: formData,
      type: 'patch',
      processData: false,
      contentType: false,
      success: (data) => {
        if (data["status"] == 200) {
          _this.element.notice.success("情報を更新しました")
        } else {
          _this.element.notice.success("情報を更新が失敗しました")
        }
      },
      error: (data) => {
        _this.element.notice.success("情報を更新が失敗しました")
      },
    })
  }
}
