import { Controller } from "stimulus"
import feather from 'feather-icons'

export default class extends Controller {
  static targets = []

  connect() {
    feather.replace()
  }
  open() {
    $('.offcanvas-collapse').toggleClass('open')
  }

  close() {
    $('.offcanvas-collapse').toggleClass('open')
  }
}