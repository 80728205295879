import { Controller } from "stimulus"
import Sortable from "sortablejs";
import feather from 'feather-icons'
import dayjs from "dayjs";

export default class extends Controller {
  static targets = ["view_image_modal", "delete_modal", "delete_image_modal", "delete_group_modal", "group_checkbox", "check_all"];

  history_comment_page = 0;
  group_checked_ids = [];
  group_text_enter_text = "";

  initialize() {
    this.current_user_id = window.ig_analitics_params.user_id;
    this.email_form = $('.email_select').select2(
      {
        tags: true,
        placeholder: "",
        tokenSeparators: [',', ' '],
        width: '100%',
        insertTag: function (data, tag) {
          data.push(tag);
        },
        dropdownParent: $('.email_select'),
        createTag: (term, data) =>{
          var value = term.term;
          function validateEmail(email) { 
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
          } 
          if(validateEmail(value)) {
            return {
              id: value,
              text: value
            };
          }
          return null;   
        } 
      }
    )
  }

  connect() {
    this.element[this.identifier] = this;
    this.refresh_all();
  }

  refresh_all() {
    this.history_comment_page = 0;
    this.refresh_comment();
    this.refresh_maillist();
    this.refresh_group_list();
  }
  add_schedule_group_content(event, schedule_group_id="", group_policy="", image_policy="", post_count="", last_month_result="", color="", list_type="") {
    let _this = this;
    let number_count = (_this.schedule_group_list_number + 1);
    let html = "";
    let checked = this.group_checked_ids.indexOf(schedule_group_id) != -1 ? "checked" : "";
    if (list_type != this.element.schedule.get_selected_list_type && schedule_group_id != "") {
      html = `<tr></tr>`;
    }else{

      html = `<tr data-visible="true" data-schedule_group_id="${schedule_group_id}" data-schedule_group_color="${color}" data-list_type="${list_type}">
        <div>abcdefd</div>

        <td class="number">
          <div class="input_checkbox">
            <input type="checkbox" ${checked} name="schedule_group_id" value="${schedule_group_id}" data-action="click->scheduleGroup#checkbox_click" data-target="scheduleGroup.group_checkbox" ${checked && `style="background-color:${color};"`}>
          </div>
          <div class="number_count">${number_count}</div>
        </td>
        <td class="group_policy">
          <div class="text" data-action="click->scheduleGroup#input_text_click">${group_policy}</div>
          <input type="text" maxlength="30" data-action="focusout->scheduleGroup#change_text_focusout keydown->scheduleGroup#change_text_keydown" name="group_policy" value="${group_policy}" style="display:none;">
        </td>
        <td class="image_policy">
          <div class="text" data-action="click->scheduleGroup#input_text_click">${image_policy}</div>
          <input type="text" maxlength="30" data-action="focusout->scheduleGroup#change_text_focusout keydown->scheduleGroup#change_text_keydown" name="image_policy" value="${image_policy}" style="display:none;">
        </td>
        <td class="post_count">${post_count || 0}</td>
        <td class="last_month_result">${last_month_result || 0} pt</td>
        <td class="delete_button" data-action="click->scheduleGroup#remove_group_confirm">
          <span class="icon">
            ${feather.icons.x.toSvg({'stroke-width': 3, color: 'red' , width: 18, height: 18})}
          </span>
        </td>
        </tr>
      `
    }

    if (number_count > 15) return;
    $(".schedule_group_table tbody").append(html);

  }

  check_clear() {
    let _this = this;
    $(this.check_allTarget).prop("checked", false);
    this.group_checkboxTargets.forEach((element) => {
      let id = $(element).parents("tr").data("schedule_group_id");
      $(element).prop("checked", false);
      $(element).css("background-color", "#fff");
      let index = _this.group_checked_ids.indexOf(id);
      if (index !== -1) _this.group_checked_ids.splice(index, 1);
    })
  }

  shcedule_check_all(event) {
    let _this = this;
    if (event.target.checked) {
      this.group_checkboxTargets.forEach((element) => {
        let color = $(element).parents("tr").data("schedule_group_color");
        let id = $(element).parents("tr").data("schedule_group_id");
        if (id == "") {
          return;
        }
        _this.group_checked_ids.push(id);
        $(element).prop("checked", true);
        $(element).css("background-color", color);
      })
    } else {
      this.check_clear();
    }
    this.checked_content_change_background();
  }
  checkbox_click(event) {
    let color = $(event.target).parents("tr").data("schedule_group_color");
    let id = $(event.target).parents("tr").data("schedule_group_id");
    if (id == "") {
      $(event.target).css("background-color", "#fff");
      return;
    };

    if (event.target.checked) {
      $(event.target).css("background-color", color);
      this.group_checked_ids.push(id);
    } else {
      $(event.target).css("background-color", "#fff");
      let index = this.group_checked_ids.indexOf(id);
      if (index !== -1) this.group_checked_ids.splice(index, 1);
    }
    this.checked_content_change_background();
  }
  input_text_click(element){
    $(element.target).css("display","none");
    $(element.target).next().css("display","block");
    $(element.target).next().focus();
  }

  change_text_focusout(element) {
    let text = $(element.target).val()
    this.change_text(element);
    if (text.length == 0 || this.group_text_enter_text == text) return;
    this.update_schedule_group(element);

  }

  change_text_keydown(element) {
    let text = $(element.target).val()
    if (text.length == 0) return;
    if (element.key == "Tab" || (element.key == "Enter" && !element.isComposing) ){
      this.group_text_enter_text = text;
      this.change_text(element);
      this.update_schedule_group(element);
    }
   }

  change_text(element) {
    $(".text-input", element.target).val($(".name", element.target).text());
    $(element.target).prev(".text").html($(element.target).val().replace(/\r?\n/g, '<br>')).css("display","block");
    $(element.target).css("display","none");
  }

  update_schedule_group(element) {
    let _this = this;
    let parent = $(element.target).parents("tr");
    let schedule_group_id = parent.data("schedule_group_id");
    let group_policy = parent.find("[name=group_policy]").val();
    let image_policy = parent.find("[name=image_policy]").val();
    $.ajax({
      url: "/client/api/v1/schedules/group",
      data: JSON.stringify({sns_url_id: this.element.schedule.selected_sns_url.id, list_type: this.element.schedule.get_selected_list_type, schedule_group_id: schedule_group_id, group_policy: group_policy, image_policy: image_policy}),
      type: 'post',
      contentType: 'application/json',
      success: (data) => {
        // feather.replace();
        _this.refresh_group_list();
      }
    })
  }

  refresh_group_list(){
    let _this = this;
    let date = dayjs(_this.element.schedule.calendar_date).format("YYYY-MM-DD");
    $.ajax({
      url: "/client/api/v1/schedules/group",
      data: {sns_url_id: this.element.schedule.selected_sns_url.id, list_type: this.element.schedule.get_selected_list_type, date: date},
      type: 'get',
      contentType: 'application/json',
      success: (data) => {
        $(".schedule_group_table tbody").empty();
        data.forEach(group => _this.add_schedule_group_content(null, group.id, group.group_policy, group.image_policy, group.post_count, group.last_month_result, group.color, group.list_type))
        //  _this.email_form.empty();
        //  data.forEach(email => _this.email_form.append(new Option(email, email, null,true)))
        Sortable.create(document.getElementsByClassName('schedule_group_table_contents')[0] ,{ 
          cursor: 'move',
          opacity: 0.7,
          placeholder: 'ui-state-highlight',
          onUpdate: (event) => {
            $.ajax({
              url: "/client/api/v1/schedules/sort_group",
              type: "patch",
              data: {sns_url_id: this.element.schedule.selected_sns_url.id, list_type: this.element.schedule.get_selected_list_type, from: event.oldIndex, to: event.newIndex},
            })
          }
         } );

      }
    }) 
  }

  refresh_maillist() {
    let _this = this;
    $.ajax({
      url: "/client/api/v1/schedules/maillist",
      data: {sns_url_id: this.element.schedule.selected_sns_url.id, list_type: 0},
      type: 'get',
      contentType: 'application/json',
      success: (data) => {
         _this.email_form.empty();
         data.forEach(email => _this.email_form.append(new Option(email, email, null,true)))
      }
    })
  }
  send_emaillist() {
    let _this = this;
    let formData = new FormData();
    formData.append("emaillist",JSON.stringify(this.email_form.val()));
    formData.append("sns_url_id",this.element.schedule.selected_sns_url.id);
    // formData.append("list_type",this.element.schedule.get_selected_list_type)
    formData.append("list_type",0);
    $.ajax({
      url: "/client/api/v1/schedules/maillist",
      data: formData,
      type: 'post',
      processData: false,
      contentType: false,
      success: (data) => {
        _this.element.notice.success("メールアドレスを登録しました。");
        _this.refresh_maillist();
      },
      error: (data) => {
        _this.element.notice.warning("メールアドレスの登録に失敗しました。");
        _this.refresh_maillist();
      }
    })
  }

  refresh_comment() {
    let _this = this;
    _this.block = true;
    let year_month = dayjs(this.element.schedule.calendar_date).format("YYYYMM");
    $(".schedule_history_view").off("scroll");

    $.ajax({
      url: "/client/api/v1/schedules/history_comment",
      data: {sns_url_id: this.element.schedule.selected_sns_url.id, list_type: 0, page: this.history_comment_page, year_month: year_month},
      type: 'get',
      contentType: 'application/json',
      success: (data) => {
        if (_this.history_comment_page == 0) {
          $(".schedule_history_comment_wrapper").empty();
        }

        data.forEach(comment_data => {
          let images_html = '';
          comment_data.images.forEach(image => {
            images_html += `<div class="history_comment_image" data-image_id=${image.id}>
             <img src="${image.thumbnail}" data-action="click->scheduleGroup#view_image" data-image=${image.path}>
               ${
                 (_this.current_user_id == comment_data.user_id) 
                 ? `<div class="delete_comment_image" data-action="click->scheduleGroup#remove_comment_image_confirm">
                   ${feather.toSvg("x-circle",{'stroke-width': 2, color: 'red' , width: 15, height: 15})}
                 </div>` 
                 : ''
               }
             </div>`;
           })

          let html = `<div class="history_comment" data-post_user_id="${comment_data.user_id}" data-post_id=${comment_data.id}>
            <div class="history_comment_header">
              <div class="post_at">${comment_data.post_at}</div>
              <div class="user_name">投稿者: ${comment_data.user_name}</div>
              ${
                (_this.current_user_id == comment_data.user_id) 
                ? `<div class="delete_comment" data-action="click->scheduleGroup#remove_comment_confirm">削除</div>` 
                : ''
              }
            </div>
            <div class="history_comment_body">${comment_data.content.replace(/\r?\n/g, '<br>')}</div>
            <div class="history_comment_images">${images_html}</div>
            ${
              (_this.current_user_id == comment_data.user_id) 
              ? `<div class="history_comment_image_upload" data-post_id=${comment_data.id}>
                  <label>
                  ${feather.toSvg("upload",{'stroke-width': 2, color: '#aaa' , width: 15, height: 15})}
                    <input type="file" multiple="multiple" accept="image/jpeg, image/png, image/jpg" data-action="change->scheduleGroup#upload_files">
                  </label>
                  <div class="spinner-border text-info spinner-border-sm" role="status" style="display:none;"></div>
                </div>` 
              : ''
            }
          </div>`;

          html = $(".schedule_history_comment_wrapper").append($(html));
        })
        $(".schedule_history_view").on("scroll", () => {
          _this.bottom_comment_scroll(_this);
        });
        _this.block = false;
      }
    })
  }

  bottom_comment_scroll(stimulus) {
    // $(".schedule_history_comment_wrapper").scrollTop(scrollHeight);
    let scrollTop = $(".schedule_history_view").scrollTop();
    let innerHeight = $(".schedule_history_view").innerHeight();
    let scrollHeight = $(".schedule_history_comment_wrapper")[0].scrollHeight;
    let isScorllFinish = scrollTop + innerHeight >= (scrollHeight -1);
    if (isScorllFinish && stimulus.block == false) {
      stimulus.history_comment_page = stimulus.history_comment_page + 1;
      stimulus.refresh_comment();
    }
    
  }

  view_image (event) {
    $(".view_image_modal").find("img").attr("src",event.target.dataset.image);
    let modal = this.application.getControllerForElementAndIdentifier(
      this.view_image_modalTarget,
      "modal"
    );
    modal.open();
  }
  send_comment(event) {
    let _this = this;
    let formData = new FormData();
    let year_month = dayjs(this.element.schedule.calendar_date).format("YYYYMM");
    formData.append("history_comment",$("#history_comment").val());
    formData.append("sns_url_id",this.element.schedule.selected_sns_url.id);
    formData.append("list_type",null);
    formData.append("year_month",year_month);
    $.ajax({
      url: "/client/api/v1/schedules/history_comment",
      data: formData,
      type: 'post',
      processData: false,
      contentType: false,
      success: (data) => {
        _this.element.notice.success("コメントを投稿しました");
        _this.history_comment_page = 0;
        $(".schedule_history_view").scrollTop(0);
        _this.refresh_comment();
        $("#history_comment").val("");
      },
      error: (data) => {
        _this.element.notice.warning("コメントの投稿に失敗しました");
      }
    })
  }

  upload_files(event) {
    let _this = this;
    let formData = new FormData();
    let post_id = event.target.parentElement.parentElement.dataset.post_id;
    let label = $(event.target).parent();
    label.css("display","none");
    label.next().css("display","inline-block");
    if (event.target.files.length == 0) return;
    formData.append("schedule_history_id", post_id);
    formData.append("sns_url_id",this.element.schedule.selected_sns_url.id);
    // formData.append("list_type",this.element.schedule.get_selected_list_type);
    formData.append("list_type",null);
    event.target.files.forEach(file => formData.append("files[]", file));
    $.ajax({
      url: "/client/api/v1/schedules/history_image",
      data: formData,
      type: 'post',
      processData: false,
      contentType: false,
      success: (data) => {
        _this.element.notice.success("ファイルをアップロードしました");
        _this.history_comment_page = 0;
        _this.refresh_comment();
        event.target.value = "";
      },
      error: (data) => {
        _this.element.notice.warning("ファイルアップロードが失敗しました");
      }
    })
  }

  delete_comment(event){
    let _this = this;
    $.ajax({
      url: "/client/api/v1/schedules/history_comment",
      data: {
        id: this.current_post_id,
      },
      type: 'delete',
      success: (data) => {
        _this.element.notice.success("コメントを削除しました");
        _this.refresh_comment();
        _this.remove_comment_confirm_close();
      },
      error: (data) => {
        _this.element.notice.warning("コメントの削除に失敗しました");
      }
    })

  }
  remove_comment_confirm(event) {
    this.current_post_id = event.target.parentElement.parentElement.dataset.post_id;
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_modalTarget,
      "modal"
    );
    delete_modal.open();
  }
  remove_comment_confirm_close() {
       let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_modalTarget,
      "modal"
    );
    delete_modal.close(); 
  }

  delete_schedule_group() {
    let _this = this;
    $.ajax({
      url: "/client/api/v1/schedules/group",
      data: {
        sns_url_id: _this.element.schedule.selected_sns_url.id,
        schedule_group_id: _this.current_group_id,
      },
      type: 'delete',
      success: (data) => {
        _this.refresh_group_list();
        _this.remove_group_confirm_close();
        let index = _this.group_checked_ids.indexOf(_this.current_group_id);
        if (index !== -1) _this.group_checked_ids.splice(index, 1);

        setTimeout(_this.checked_content_change_background, 100);
        _this.element.notice.success("グループを削除しました");
      },
      error: (data) => {
        _this.element.notice.warning("グループの削除に失敗しました");
        _this.remove_group_confirm_close();
      }
    })
  }

  remove_comment_image_confirm(event) {
    this.current_post_image_id = $(event.target).parents(".history_comment_image").data("image_id");
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_image_modalTarget,
      "modal"
    );
    delete_modal.open();
  }
  remove_comment_image_confirm_close() {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_image_modalTarget,
      "modal"
    );
    delete_modal.close(); 
  }

  remove_group_confirm(event) {
    this.current_group_id = $(event.target).parents("tr").data("schedule_group_id");
    if (this.current_group_id == "") {
      // 新規の時はそのまま削除する
      $(event.target).parents("tr").remove();
      return;
    }
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_group_modalTarget,
      "modal"
    );
    delete_modal.open();
  }

  remove_group_confirm_close() {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_group_modalTarget,
      "modal"
    );
    delete_modal.close(); 
  }

  onScroll(event) {
    console.log(event);
  }

  delete_comment_image() {
    let _this = this;
    $.ajax({
      url: "/client/api/v1/schedules/history_image",
      data: {
        id: this.current_post_image_id,
      },
      type: 'delete',
      success: (data) => {
        _this.element.notice.success("画像を削除しました");
        _this.refresh_comment();
        _this.remove_comment_image_confirm_close();
      },
      error: (data) => {
        _this.element.notice.warning("画像の削除に失敗しました");
      }
    })
  }

  checked_content_change_background() {
    let tbody = $(".schedule_group_table tbody tr"); 

    $(".calendar-content, .fc-content").parent().css("border", "none"); 
    $(".fc-content").parent().css("background", "#2f2f2f");

    if (tbody.find("input[type=checkbox]:checked").length == 0) {
      $(".schedule_group_mask").remove();
      return;
    }
    $(".calendar-content").parent().append(`<div class="mask schedule_group_mask"></div>`)
     tbody.each((index, item) => {
      if ($(item).find("input[type=checkbox]").prop("checked")) {
        $(".calendar-content, .fc-content").each((indexj, content) => {
          if ($(content).data("schedule_group_id") == $(item).data("schedule_group_id") || $(content).parent().data("schedule_group_id") == $(item).data("schedule_group_id") ) {
            $(content).parent().css("border", "2px solid " + $(item).data("schedule_group_color"))
            if ($(content).hasClass("fc-content")) {
              $(content).parent().css("background", $(item).data("schedule_group_color"))
            }
            $(content).parent().find(".schedule_group_mask").remove();
          } 
        })
      } else {
      }
    })
  }

  get schedule_group_list_number() {
    return $(".schedule_group_table tbody tr[data-visible=true]").length;
  }

  get selected_group_list() {
    let list = [];
    $(".schedule_group_table tbody tr").each((index, item) => {
      if ($(item).find("input[type=checkbox]").prop("checked")) {
        list[$(item).data("schedule_group_id")] = $(item).data("schedule_group_color");
      }
    })
    return list;
  }
}  