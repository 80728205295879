// app/javascript/controllers/csv_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

  }

  download_csv() {
    location.href= "https://igcounter.pla-cole.co/client/api/v1/sns_urls/csv";
  }
}
