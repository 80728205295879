import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "followers", "likes","submit_button" ]

  connect() {
    this.element[this.identifier] = this;
  }

  fetch() {
    $.ajax({
      type: "GET",
      url: "/client/api/v1/goal_settings?sns_url_id="+this.element.history.sns_url_ids[0],
      contentType: 'application/json',
      data: {sns_url_ids: this.sns_url_ids},
      success: (data) => {
        data.follower_datas.forEach((el, i) => {
          var date = Number(el.date.split('-')[1])
          $('input[name="goal_settings[follower['+date+']]"]').val(el.value);
        })
        data.like_datas.forEach((el, i) => {
          var date = Number(el.date.split('-')[1])
          $('input[name="goal_settings[like['+date+']]"]').val(el.value);
        })
      }
    });
  }

  save() {
    $(this.submit_buttonTargets[0]).prop("disabled", true)
    var formData = new FormData();
    formData.append("sns_url_id", this.element.history.sns_url_ids[0])
    this.followersTargets.forEach((el, i ) => {
      formData.append(el.name, el.value) 
    }) 
    this.likesTargets.forEach((el, i ) => {
      formData.append(el.name, el.value) 
    }) 

    $.ajax({
      type: "POST",
      url: "/client/api/v1/goal_settings",
      data: formData,
      contentType: false,
      processData : false,
      success: (data) => {
        this.modal_close();
        $(this.submit_buttonTargets[0]).prop("disabled", false)

      }
    });

   }

  modal_open() {
    this.fetch();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
     modalController.open();
  }


  modal_close() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    this.element.history.search()
    modalController.close();
  }



}
