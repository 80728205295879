import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [  ]

  stacks = [];

  connect() {
    this.element[this.identifier] = this;
  }
  open() {
    // let ele = $(this.element)
    let ele = $("#global_notice_area"); 
    let _this = this
    let open_notice = () => {
      if (_this.stacks.length > 0 && !ele.hasClass("slideup")) {
        ele.addClass(_this.stacks[0].type);
        ele.find(".notice_text").html(_this.stacks[0].text)
        _this.stacks.shift();
        ele.addClass("slideup")
      }
    }
    open_notice();
    ele.on('animationend' ,() => {
      ele.removeClass("slideup")
      _this.remove_classes();
      setTimeout(open_notice, 500)
    })
  }
  remove_classes() {
    let classes = [
      "success", "slideup", "error", "warning"
    ]
    let ele = $("#global_notice_area"); 
    classes.forEach((e,i) => {
      ele.removeClass(e);
    })
  }
  error(text) {
    this.stack_push( "error", text);
    this.open_notice();
  }

  warning(text) {
    this.stack_push( "warning", text);
    this.open_notice();
  }

  success(text) {
    this.stack_push( "success", text);
    this.open_notice();
  }

  open_notice() {
    let _this = this;
    setTimeout(() => {_this.open()}, 500);
  } 
  stack_push(type, text) {
    if (this.stacks.filter(item => item.text == text && item.type == type).length > 0) {
      return;
    }
    this.stacks.push({
      type: type,
      text: text
    });
  }

}
