import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = [ "sns_url_id", "url", "sub_sns_url_id", "modal", "add_tag_modal", "form", "hashtag_id" ]

  connect() {
    this.load();

    let noticeController = this.getControllerByIdentifier("notice")

    if (this.data.has("refreshInterval")) {
        this.start()
    }
    let result = location.hash.split("=").at(-1);
    if (result != '') {
      history.replaceState('','',location.pathname);
      if (result == "true") {
        noticeController.success("Instagramアカウントと連携しました");
      } else {
        noticeController.error("Instagramアカウントの連携が失敗しました");
      }
    }
  }

  disconnect() {
    this.stop();
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  load() {
    $.ajax({
      type: "GET",
      url: "/client/api/v1/statuses/sns_urls",
      success: (data) => {
        this.update(data);
        let current_id = this.current_id;
        let current_urls = $.grep(data,
          function(elem, index) {
            return (elem.id == current_id);
          }
        ).shift();
        let sub_sns_ids = (current_urls) ? current_urls.sub_sns_url_ids : [];
        this.getControllerByIdentifier("modal").benchmark_data_append("urls",data, sub_sns_ids);

        Sortable.create(document.getElementsByClassName('id_group_table_contents')[0] ,{ 
          cursor: 'move',
          opacity: 0.7,
          placeholder: 'ui-state-highlight',
          onUpdate: (event) => {
            const childElements = event.from.children;
            const snsUrlIds = [];

            for (let i = 0; i < childElements.length; i++) {
              const childElement = childElements[i];
              const snsUrlId = childElement.getAttribute('value');
              if (snsUrlId) {
                snsUrlIds.push(Number(snsUrlId));
              }
            }

            const jsonData = JSON.stringify(snsUrlIds);

            $.ajax({
              url: "/client/api/v1/statuses/sns_url_sort",
              type: "post",
              data: {sns_url_ids: jsonData},
              success: (response) => {
                if (response.status === "error") {
                  alert("並び替えに失敗しました。もう一度行ってください");
                } 
              },
              error: (error) => {
                alert("並び替えに失敗しました。もう一度行ってください");
              }
            })
          }
         } );
      }
    })
  }

  update(data) {
    data.forEach((el,i) => {
      let target = this.sns_url_idTargets.find(target => {
        return $(target).attr("value") == el["id"]
      })
      $(target).find("#initial_running_button").css("display","none");
      $(target).find(".id_regist .complete").css("display","none");
      $(target).find(".id_regist .loading").css("display","none");
      if (el["hashtag"] != null) {
        $(target).find(".add_tag button").text("#" + el["hashtag"]["name"]).addClass("tag_exists")
      } else {
        $(target).find(".add_tag button").text("未設定").removeClass("tag_exists")
      }
      $(target).find("#delete_comfirm_button").css("display","inline-block");
      $(target).find(".fullname").html(el["fullname"]);
      if (el["status"] == "pending" && el["fullname"] != null) {
        $(target).find("#initial_running_button").css("display","inline-block");
      } else if(el["status"] == "complete") {
        $(target).find(".id_regist .complete").css("display","inline-block");
      } else if (el["status"] == "notfound")  {
        $(target).find(".id_regist .not_found").css("display","inline-block");
        $(target).find(".fullname").html("無効なURLです");
      } else if (el["status"] == "error")  {
        $(target).find(".id_regist .not_found").css("display","inline-block");
        $(target).find(".fullname").html("URL情報の取得に失敗しました");
      }else {
        $(target).find(".id_regist .loading").css("display","inline-block");
      }
    })
  }
  submit_check(ele){
    let noticeController = this.getControllerByIdentifier("notice")
    if ($("#sns_url_url",$(ele.target)).val() == ""){
      noticeController.warning("URLを入力してください");
      ele.preventDefault();
    } else {
      if ($("#sns_urls tr").length <= 1) {
        noticeController.success("アカウント追加は基本プランとなります。");
      }
    }
  }

  submit_disable(ele) {
      $(ele.target).find(".submit").addClass("disabled");
      $(ele.target).find(".submit").attr("disabled","disabled");
  }

  submit_enable(ele) {
    $(ele.target).find(".submit").removeClass("disabled");
    $(ele.target).find(".submit").removeAttr("disabled");
    let [data, status] = ele.detail;
    let result = "";
    let noticeController = this.getControllerByIdentifier("notice")
    try {
      result = $.parseJSON(data.response);
    } catch {}
    if (result["result"]) {
      noticeController.error(result["result"]["flash"]);
    }else {
      // if (status == "OK") {
        noticeController.success("URLを登録しました。");
      // } else {
      //   noticeController.error("エラーが発生しました。");
      // }
    }
  }

  loading(ele) {
    let parent = $(ele.srcElement).parent();
    parent.css("display","none");
    parent.parents('tr').find(".loading").css("display","inline-block");
  }

  start() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.data.get("refreshInterval"))
  }

  stop() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  add_tag_modal_open(ele) {
    let _this = this
    _this.stop();
    let modalController = _this.application.getControllerForElementAndIdentifier(
      _this.add_tag_modalTarget,
      "modal"
    );
    _this.current_id = $(ele.srcElement).attr("data-value")
    $.ajax({
      type: "get",
      url: "/client/api/v1/sns_urls/" + _this.current_id,
      success: (data) => {
        _this.getControllerByIdentifier("modal").add_tag_data_append("urls", data["hashtag_id"]);
        modalController.open();
      }
    })
  }
  add_tag_modal_close(ele) {
    this.start();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.add_tag_modalTarget,
      "modal"
    );
    $(this.add_tagTarget)
    this.hashtag_id = $("option:selected", $(this.hashtag_idTarget)).val()
    $.ajax({
      type: "patch",
      url: "/client/api/v1/sns_urls/hashtag",
      data: {hashtag_id: this.hashtag_id, id: this.current_id },
      success: (data) => {
      }
    })
    this.load()
    modalController.close();
  }

  modal_open(ele) {
    this.stop();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    this.current_id = $(ele.currentTarget).attr("data-value");
    this.load();
    modalController.open();
  }
  modal_close() {
    this.start();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );

    $.ajax({
      type: "patch",
      url: "/client/api/v1/sns_urls/add_sub_sns_url",
      data: {sub_sns_url_ids: this.urls, id: this.current_id },
      success: (data) => {
      },
      error: (textStatus, errorThrown) => {
        console.error("Error: ", textStatus, errorThrown);
        alert("問題が発生しました。もう一度やり直してください。");
      }
    })
    modalController.close();
  }

  onclick_check() {

  }

  get urls() {
    let urls = [];
    this.sub_sns_url_idTargets.forEach((el, i ) => {
      if (el.checked) {
        urls.push(parseInt($(el).attr("value")));
      }
    })
    return urls;
  }
}
