import { Controller } from "stimulus"
import feather from 'feather-icons'


export default class extends Controller {
  static targets = ["modal", "image", "video"];
  
  main_image;
  usertags = [];
  x = 0;
  y = 0;
  page = 0;
  itemno = 0;
  usertag_histories = [];

  connect() {
    this.element[this.identifier] = this;
    this.usertags =[];
  }

  async modal_open(event, usertags=[]) {
    $(".user_tag_text").remove();
    this.page = $(event.target).data("page");
    this.itemno = $(event.target).data("itemno");
    this.usertags[this.itemno] = usertags;
    this.usertag_histories = [];

    const extension = event.target.src.split('.').pop().toLowerCase();

    if (extension === 'mov' || extension === 'mp4' || event.target.src.startsWith("blob")) {
      $("#user_tag_modal_image").css("display", "none");
      $("#user_tag_modal_video").css("display", "block");
      this.main_video = this.modalTarget.querySelector("#user_tag_image video");
      this.main_video.src = event.target.src;
    } else {
      $("#user_tag_modal_image").css("display", "block");
      $("#user_tag_modal_video").css("display", "none");
      this.main_image = this.modalTarget.querySelector("#user_tag_image img");
      this.main_image.src = event.target.src;
    }


    this.usertag_histories = await $.ajax({
      url: "/client/api/v1/schedules/user_tag",
      data: {sns_url_id: this.element.schedule.selected_sns_url.id},
      type: 'get',
    }).then((data) => data);
    this.add_usertags_histories(this.usertag_histories);
    this.username_search("");
    this.modalController.open();
    setTimeout(() => {
      this.usertags[this.itemno].forEach((el, i) => {
        if (el.username == undefined ) return;
        let position = this.change_xy(el.x, el.y);
        if (Number.isInteger(el.x) && Number.isInteger(el.y)) {
          position = {x: el.x, y: el.y};
        }
        this.show_tag(position.x, position.y, el.username);
      });
    }, 500);
  }

  modal_close() {
    this.usertags = [];
    this.usertag_histories = [];
    $(".username_input_wrapper").css("visibility", "hidden");
    $(".username_input_wrapper input").val("");
    this.modalController.close();
  }

  image_click(event) {
    this.x = event.offsetX;
    this.y = event.offsetY;
    let image = $("#user_tag_image img");
    let position_x = this.x - 50;
    let position_y = this.y - 10;
    if (position_y / image.height() > 0.8) position_y = this.y - 70;

    $(".username_input_wrapper")
      .css("left", position_x + "px")
      .css("top", position_y + "px")
      .css("visibility", "visible");
    $(".username_input_wrapper input").focus();
    this.username_search("");

  }

  username_keydown(event) {
    let _this = this;
    if (event.key == "Enter") {
      let username = $(".username_input input").val();
      if (username == "") return;
      if (/^[a-zA-Z0-9._]{3,30}$/.test(username) == false){
        this.element.notice.warning("ユーザーネームは英数字、._のみ使用できます。");
        return;
      }
      if (this.usertags[this.itemno].filter((el) => el.username == username).length > 0) {
        this.element.notice.warning("同じユーザーネームは登録できません。");
        return;
      }
      this.add_username(username);
    } else {
      setTimeout(() => {
        let username = $(".username_input input").val();
        _this.username_search(username);
      },100);
    }
  }

  
  username_search(username) {
    if (username == "") {
      $(".username_history_item").css("display", "flex");
    }
    $(".username_history_item").css("display", "flex");
    $(".username_histories .username_history_item").each((index, el) => {
      if ($(el).children(".username_history_item_name").text().indexOf(username) !== 0) {
        $(el).css("display", "none");;
      }
    })

    $(".username")
  }

  username_clear(event) {
    $(".username_input input").val("");
    $(".username_input_wrapper").css("visibility", "hidden");
  }
  select_usertag(event) {
    let username = $(event.target).text();
    this.add_username(username); 
  }

  delete_usertag_history(event) {
    let parent = $(event.target).parents(".username_history_item");
    parent.remove();
    $.ajax({
      url: "/client/api/v1/schedules/user_tag",
      data: {sns_url_id: this.element.schedule.selected_sns_url.id, tagname: $(".username_history_item_name",parent).text()},
      type: 'delete',
    });
  }

  add_username(username){
    let position = this.change_xy(this.x, this.y);
    this.usertags[this.itemno].push({ x: position.x, y: position.y, username: username });
    $(".username_input_wrapper").css("visibility", "hidden");
    $(".username_input_wrapper input").val("");
    this.show_tag(this.x, this.y, username);
    this.add_usertags_histories(username);
    $.ajax({
      url: "/client/api/v1/schedules/user_tag",
      data: {sns_url_id: this.element.schedule.selected_sns_url.id, tagname: username},
      type: 'post',
      success: function (data) {
      }
    });
  }

  usertag_save() {
    this.element.schedule.usertag_save();
    this.modal_close();
  }

  usertag_cancel() {
    this.usertags[this.itemno] = [];
    this.modal_close();
  }
  // 画像のクリック位置からinstagramのタグ位置を設定する, 逆の場合は実数値に変更する
  change_xy(x , y) {
    let position_xy;

    if ($("#user_tag_modal_image").css("display") === "block" && $("#user_tag_modal_video").css("display") === "none") {
      position_xy = this.calculate_position(x, y, $("#user_tag_image img"));
    } else {
      position_xy = this.calculate_position(x, y, $("#user_tag_image video"));
    }

    return position_xy;
  }

  calculate_position(x, y, $element) {
    let width = $element.width();
    let height = $element.height();
    let position_x, position_y;
  
    if (Number.isInteger(x) && Number.isInteger(y)) {
      position_x = x / width;
      position_y = y / height;
    } else {
      position_x = x * width;
      position_y = y * height;
    }
  
    return { x: position_x, y: position_y };
  }

  show_tag(x, y, username) {
    $("#user_tag_image_wrapper .user_tags")
      .append(`<div class="user_tag_text" style="left: ${x-60}px; top: ${y}px;">
        ${username}
        <div class="user_tag_delete_icon" data-action="click->usertags#delete_usertag"></div>
      </div>`)
    .hover((event) => {
      $(".user_tag_delete_icon").css("visibility", "hidden");
      if (event.type == "mouseleave") {
        $(".user_tag_delete_icon", $(event.target)).css("visibility", "hidden");
      } else {
        $(".user_tag_delete_icon", $(event.target)).css("visibility", "visible");
      }
    });
  }

  add_usertags_histories(usertag_history){
    let _this = this;
    if (typeof usertag_history == "string") {
      let html = $(_this.username_history_template);
      this.usertag_histories.unshift(usertag_history);
      $(".username_history_item_name" ,html).html(usertag_history);
      $(".username_histories .username_history_item").each((index, el) => {
        if ($(el).children(".username_history_item_name").text() == usertag_history) {
          el.remove();
        }
      })
      $(".username_histories").prepend(html);
    }else {
      $(".username_histories").empty();
      this.usertag_histories = usertag_history;
      usertag_history.forEach(function(el){
        let html = $(_this.username_history_template);
        $(".username_history_item_name" ,html).html(el);
        $(".username_histories").append($(html));
      })
    }
  }

  delete_usertag(event) {
    let parent = $(event.target).parent();
    let index = parent.index();
    this.usertags[this.itemno][index] = null;
    this.usertags[this.itemno] = this.usertags[this.itemno].filter(Boolean);
    parent.remove();
  }
  set set_usertags(usertags) {
    this.usertags = usertags;
  }
  get get_usertags() {
    return this.usertags[this.itemno];
  }
 
  get username_history_template() {
    return `
      <div class="username_history_item">
        <div class="username_history_item_name" data-action="click->usertags#select_usertag"></div>
        <div class="username_history_item_delete" data-action="click->usertags#delete_usertag_history">
          ${feather.icons.x.toSvg({'stroke-width': 2, color: 'black' , width: 14, height: 14})}
        </div>
      </div>
    `;
  }

  get current_itemno() {
    return this.itemno;
  }
  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
  }


}
