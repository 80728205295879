
import { Controller } from "stimulus"
import Dropzone from "dropzone";
import Swiper from 'swiper';
import JSZip from "jszip";

export default class extends Controller {
  static targets = [ "directories", "directory",  "files", "file", "delete_modal" , "file_carousel", "file_carousel",
    "library_select_modal"]

  TYPES = {
    DIR: "directory",
    FILE: "file",
  };


  is_new_directory = false;
  is_detail = false; // 詳細画面か否か（削除用）
  current_dir_id = null;
  dropzone = null;
  current_type = this.TYPES.DIR;
  current_file_index = 0;
  current_file_id = 0;
  only_reference = false; // 参照のみ
  multiple_fileselect = false; // 複数選択可能か
  is_bottom = false;

  initialize() {
    let _this = this;
    this.noticeController = _this.getControllerByIdentifier("notice")

    // file upload
    _this.only_reference = this.data.get("only_reference")
    _this.multiple_fileselect = this.data.get("multiple_fileselect")
    if (_this.only_reference) {
      $("#upload-asset").hide();
      return;
    }

    var max_file_size = 300; // MB(トータルのファイルサイズ)
    Dropzone.autoDiscover = false;
    Dropzone.options.uploadAsset = {
      // maxFiles: 100,
      parallelUploads: 25,
      uploadMultiple: true,
      maxFilesize: 500,
      acceptedFiles: "image/*, video/*",
      autoProcessQueue: false,
      autoQueue: true,
      dictDefaultMessage: $(".dist_default_message_template").html(),
      dictFileTooBig: "アップロード可能なファイルサイズは{{maxFilesize}}MBまでです",
      dictInvalidFileType: "ファイルタイプが不正です", 
      dictUploadCanceled: "",
      dictMaxFilesExceeded: "同時アップロード上限です（最大:{{maxFiles}}ファイル)",
      // previewTemplate: '<div style="display:none"></div>' ,
      processingmultiple: (file) => {
      },
      maxfilesexceeded	: (file) => {
        this.removeFile(file); 
      },

      init: function () {
        var total_size = 0.0;
        this.on("successmultiple", function(file, json) {
          this.processQueue()
        })
        this.on("queuecomplete", function(file) {
          _this.noticeController.success("アップロードしました");
          _this.is_new_directory = false;
          _this.render_files();
          this.removeAllFiles(true); 
        })
        this.on("addedfiles", function(files) {
          files.forEach( (file) => {
            total_size += file.size;
          })
          total_size = parseFloat((total_size / (1024*1024)).toFixed(2));
          if ( total_size >= max_file_size) {
            _this.noticeController.warning("アップロード可能なファイルサイズはトータルで" +max_file_size+ "までです");
            this.removeAllFiles(true); 
          }else {
            this.processQueue()
          }
        });
     
        this.on("error", function(file, message) { 
          if (message == "") return;
          _this.noticeController.warning(message)
          this.removeAllFiles(true); 
        })
      }
    }
    this.dropzone = new Dropzone("#upload-asset");

    // swiper
    this.swiper_params = {
      autoHeight: true,
      loop: false,
      autoplay:false,
      width: $("#content_library").width()
      ,
      autoHeight	: false,
      navigation: {
        nextEl: '.file-carousel-control-next-icon',
        prevEl: '.file-carousel-control-prev-icon',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullet',
        clickable: true
      },
      allowTouchMove: true,
      on: {
        slideChange: function () {
          let index = _this.file_swiper.activeIndex;
          let ele = $(".file-carousel-inner .item").eq(index);
          _this.current_file_index = ele.attr("data-num")
          _this.current_file_id  =  ele.attr("data-content-id")
          _this.current_file_name  =  ele.attr("data-content-name")
          $("#content_library_file_detail .file_name .name").text(_this.current_file_name)
        },
        init: function () {
        }
      }
    }
  }
  connect() {
    let _this = this;
    this.element[this.identifier] = this;
    this.render_directories();
    this.render_box_memos();
  }

  get page() {
    return 1;
  }

  render_directories() { 
    let _this = this;
    $.ajax({
      type: "GET",
      url: "/client/api/v1/content_libraries/directory_list",
    })
    .then(
      (data) => {
        $(_this.directoriesTarget).children().remove();
        $.each(data, function(i, directory) { 
          let template = $(".directory_template").children().clone();
          template.attr("data-content-id", directory.id)
          template.attr("data-content-name", directory.name)
          let directory_name = (directory.name.length >= 18) ? directory.name.substring(0,18) + "..." : directory.name
          $(".directory_name",template).html(directory_name);
          $(_this.directoriesTarget).append(template);
        })
      }
    )

  }


  directory_create() {
    let _this = this;
    $.ajax({
      type: "POST",
      url: "/client/api/v1/content_libraries/directory",
    })
    .then(
      (data) => {
        _this.is_new_directory = true;
        _this.move_directory(null, data.id)
        _this.render_directories();
      },
      () => {}
    )
  }
 
  show_delete_button(force_selected = false) {
    let selector =  this.current_type + "_remove";
    if (this.exist_selected() || force_selected) {
      $("." + selector).css("display", "block")
    } else {
      $("." + selector).css("display", "none")
    }
  }

  show_add_button() {
    if (this.only_reference && this.exist_selected() && this.current_type == this.TYPES.FILE) {
      $("#file_select").css("display","block")
    } else {
      $("#file_select").css("display","none")
    }
  }

  file_select() {
    let _this = this;
    let items = this.file_selected_all_items;
    // console.log(items);
    // return;
    let is_multiple = this.file_selected_all_items.length > 1;
    let directory_name = $(".direcotry_name .name", $("#content_library_file-header")).text();

    items.forEach((ele, index) => {
      let content_type = ele.attr("data-content-type").split("/")[0];
      let url = ele.children(".file_image").children("img").attr("data-original-src")
      if (content_type == "video") {
        url = ele.children(".file_image").children("video").attr("data-original-src");
      }
      let content_id = ele.attr("data-content-id");
      let parent = $(_this.file_target).parents(".publish_image");
      if (is_multiple) {
        parent = $($(_this.file_target).parents(".publish_images")).children(".publish_image").find(".nothing").first().parents(".publish_image");
        if (parent.length == 0) {
          _this.element.schedule.add_publish_image(parent);
          parent = $($(_this.file_target).parents(".publish_images")).children(".publish_image").find(".nothing").first().parents(".publish_image");
        }
      }
      parent.attr("data-select-file", "true");
      parent.attr("data-library-select", "true");
      parent.attr("data-library-id", content_id);
      _this.element.schedule.fileselect(null, url, parent, content_type)
      $("input",parent).val("")
      parent.removeAttr("data-select-file");
      $(".select_upload").each((i, el) => {
        _this.element.schedule.close_file_select(el);
      })
      _this.deselected(ele);

    })

    _this.element.schedule.create_memo(directory_name);
    _this.current_type = this.TYPES.FILE;
    _this.deselect_all();
    _this.select_file_modal_close();
  }


  selected(ele) {
    let parent = $(ele).parents("." + this.current_type);
    parent.addClass("selected")
    $("#igcounter-page-content_libraries .confirm_modal .confirm_message").text(parent.attr("data-content-name") + "を削除しますか？")
  }
  deselected(ele) {
    $(ele).parents("." + this.current_type).removeClass("selected")
  }

  deselect_all() {
    $("." + this.current_type + "_list").children().each(function(i,ele) {
      $(ele).removeClass("selected")
    })
    $("." + this.current_type + "_remove").css("display", "none")
  }

  exist_selected(){ 
    let selector =  this.current_type + "_list";
    return $("."+ selector).find(".selected").length > 0;
  }

  select_item(event) {
    let _this = this;
    let ele = $(event.target).parents(".item");
    let type = $(".type", ele).attr("value");
    if (type == "directory" || (this.only_reference && !this.multiple_fileselect)) {
      _this.deselect_all();
    }
    if (ele.hasClass("selected")) {
      // 再選択(複数選択の場合のみ動作)
      _this.deselected(event.target);
    } else {
      _this.selected(event.target);
    }
    _this.show_delete_button();
    _this.show_add_button();

  }


  remove_confirm() {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_modalTarget,
      "modal"
    );
    delete_modal.open();
  }
  
  remove_modal_close() {
    let delete_modal = this.application.getControllerForElementAndIdentifier(
      this.delete_modalTarget,
      "modal"
    );
    delete_modal.close();
  }


  get file_selected_item() {
    let _this = this;
    let file_selected_item = null;
    $(".file_list").children().each( (i, el ) => {
      if ($(el).hasClass("selected")) {
        file_selected_item = $(el)
      }
    })
    return file_selected_item;
  }

  get file_selected_all_items() {
    let _this = this;
    let file_selected_items = [];
    $(".file_list").children().each( (i, el ) => {
      if ($(el).hasClass("selected")) {
        file_selected_items.push($(el));
      }
    })
    return file_selected_items;
  }

  get selected_item_ids() {
    let _this = this;
    let selected_item_ids = [];
    let targets = (this.current_type == this.TYPES.DIR) ? this.directoryTargets :  this.fileTargets;
    targets.forEach((el, i ) => {
      if ($(el).hasClass("selected")) {
        selected_item_ids.push(parseInt($(el).attr("data-content-id")));
      }
    })
    return selected_item_ids;
  }

  remove() {
    let _this = this;
    let ids = (this.is_detail) ? this.current_file_id : this.selected_item_ids;
    $.ajax({
      type: "DELETE",
      url: "/client/api/v1/content_libraries/" + _this.current_type,
      data: {content_ids: ids},
    }).then(
      (data) => {
        if (_this.current_type ==_this.TYPES.DIR) {
          _this.render_directories();
        } else {
          let item = this.file_selected_item;
          if (item != null) {
            item.remove();
          }
          if (_this.is_detail) {
            $(`[data-content-id=${ids}]`).remove();
            _this.return_file_list();
          } else {
          }
        }
        _this.noticeController.success("削除しました")
        _this.remove_modal_close();
      },
      () => {
        // error
      }
    )

  }

  directory_name_input(event) { 
    if (this.only_reference) return;
    let directory_info = $("#content_library_file-header")
    $(".direcotry_name-input", directory_info).val($(".name", directory_info).text());
    $(".name").css("display","none");
    $(".direcotry_name-input").css("display","block");
    $(".direcotry_name-input").focus();
  }

  directory_name_change() {
    let _this = this;
    let directory_id = this.current_dir_id;
    let directory_info = $("#content_library_file-header")
    $(".name", directory_info).css("display","block");
    $(".direcotry_name-input", directory_info).css("display","none");
    if ($(".name", directory_info).text() == $(".direcotry_name-input", directory_info).val() || directory_id == "") {
      return;
    }
    $.ajax({
      type: "PATCH",
      url: "/client/api/v1/content_libraries/directory",
      data: {directory_id: directory_id, name: $(".direcotry_name-input").val()},
    }).then(
      (data) => {
        $(".name", directory_info).text(data.name)
        // _this.render_files();
      },
      () => {
        // error
      }
    )
  }

  directory_name_change_enter(event) {
    if (event.keyCode == 13) {
      this.directory_name_change();
    }
  }

  return_directory_list() {
    this.current_type = this.TYPES.DIR;
    this.deselect_all();
    this.display_directory_list();
    this.is_new_directory = false;
    this.is_detail = false;
    $(".direcotry_name-input").css("display","none");
    $(".name").css("display","block");
    $("#igcounter-page-content_libraries .confirm_modal .confirm_message").text("フォルダを削除します")
  }
  
  return_file_list() {
    this.current_type = this.TYPES.FILE;
    this.is_detail = false;
    this.deselect_all();
    // this.display_file_list();
    $("#content_library_file").css("display", "block");
    $("#content_library_file_detail").css("display", "none");
    $(".file label").css("display", "flex");
  }

  render_files(_offset = null) {
    let _this = this;
    let directory_info = $("#content_library_file-header")
    let directory_id = this.current_dir_id;
    let offset = (_offset == null) ? 0 : _offset;
    let limit = 25;
    if (_offset == null) {
      $(this.filesTarget).empty();
    }

    $(".name", directory_info).text(_this.current_dirname)

    $.ajax({
      type: "GET",
      url: "/client/api/v1/content_libraries/file_list",
      data: {directory_id: directory_id, limit: limit, offset: offset},
    })
    .then(
      (data) => {
        var data_num = 0;
        if (_this.is_new_directory) {
          // _this.directory_name_input();
          _this.directory_name_input();
          $("#content_library_file-main").css("display","none")
          $(".file_upload").removeClass("small")
        } else {
          if (data.length == 0 && $(".file_list").children().length == 0) {
            $("#content_library_file-main").css("display","none")
            $(".file_upload").removeClass("small")
          }else {
            $("#content_library_file-main").css("display","block")
            $(".file_upload").addClass("small")
              data_num = $(".file_list").children().length;
          }
          $.each(data, function(i, file) { 
            // 一覧に追加
            let template = $(".file_template").children().clone();
            template.attr("data-num", data_num + i);
            template.attr("data-content-id", file.id);
            template.attr("data-content-name", file.filename)
            template.attr("data-content-type", file.content_type)

            template.append(`<input type='checkbox' id=${file.id} name /><label for=${file.id}>ファイルをDL</label>`)
            let filename = (file.filename.length >= 20) ? file.filename.substring(0,20) + "..." : file.filename
            $(".file_name",template).text(filename);
            if (file.content_type.split("/")[0] == "video" ) {
              $(".file_image",template).append("<video src='"+file.path.url+"' data-original-src='"+file.path.url+"' />")
            }else {
              $(".file_image",template).append("<img src='"+file.path.thumb_width.url+"' data-original-src='"+file.path.url+"' />")
            }
            $(_this.filesTarget).append(template);
            _this.is_bottom = false;
          })

        }
      },
      () => {}
    )


  }

  check_scroll_bottom(event) {
    let outerHeight = $(event.target).innerHeight();
    let innerHeight = $(event.target).children().innerHeight();
    let outerBottom = (innerHeight - outerHeight);
    let scrollTop = $(event.target).scrollTop() + 1;
    let childNodCount = $(this.filesTarget).children().length
    if (outerBottom <= scrollTop && !this.is_bottom) {
      this.is_bottom = true
      if (childNodCount > 0) {
        this.render_files(childNodCount)
      }
    }
  }

  move_directory(event, dir_id=null) {
    let _this = this;
    let directory_id = (event != null) ? $(event.target).parents(".directory").attr("data-content-id") : dir_id;
    this.current_dirname =　(event) ? $(event.target).parents(".directory").attr("data-content-name"): "新しいフォルダ";
    this.current_dir_id = directory_id;
    this.current_type = this.TYPES.FILE;
    if (!this.only_reference) {
      this.swiper_params.width = $("#content_library").innerWidth(); // connectだよ横幅が定まらないのでここで
      this.file_swiper = new Swiper( '#file-carousel' , this.swiper_params);
    }
    this.display_file_list();
    this.show_delete_button();
    $(".file_upload #directory_id").val(directory_id)
    $("#igcounter-page-content_libraries .confirm_modal .confirm_message").text("ファイルを削除します")

  }

  async make_swiper_items() {
    let _this = this;
    if (!this.only_reference) {
      this.file_swiper.removeAllSlides();

      $(".file_list .item").each(function(i, file) {
        let item = $(file).clone();
        let swiper_item = $(".file_template").children().clone();
        let src = $("img,video",$(item)).data("original-src"); 
        let content_type = $(item).data("content-type");
        $(item).attr("data-action", "")
        if (content_type== "video" ) {
          $(".file_image video",$(item)).attr("src", src).prop("controls",true); 
        }else {
          $(".file_image img",$(item)).attr("src", src).prop("controls",true); 
        }
        $( ".file_name",$(item)).remove();
        _this.file_swiper.appendSlide(item);
      })
    }

  }

  async move_file_detail(event) {
    await this.make_swiper_items();
    let carouselEl = $(this.file_carouselTarget)
    this.current_file_index = $(event.target).parents(".item").attr("data-num")
    this.current_file_id  =  $(event.target).parents(".item").attr("data-content-id")
    this.current_file_name  =  $(event.target).parents(".item").attr("data-content-name")
    this.is_detail = true;
    this.show_delete_button(true);
    $(".file-carousel-inner",carouselEl).each(function(i, ele) {
      $(ele).removeClass("active");
    })
    $(".file-carousel-inner",carouselEl).children().eq(this.current_file_index).addClass("active");
    this.file_swiper.slideTo(this.current_file_index, 0, false)	
    $("#content_library_file").css("display", "none");
    $("#content_library_file_detail").css("display", "block");
    $("#content_library_file_detail .file_name .name").text(this.current_file_name)
    $(".file label").css("display", "none");
  }
  

  display_directory_list() {
    this.render_directories();
    $("#content_box_memo").css("display", "block");
    $("#content_library_directory").css("display", "block");
    $("#content_library_file").css("display", "none");
  }

  display_file_list() {
    this.render_files();
    $("#content_box_memo").css("display", "none");
    $("#content_library_directory").css("display", "none");
    $("#content_library_file_detail").css("display", "none");
    $("#content_library_file").css("display", "block");
  }

  generateImagesZip(images) {
    let zip = new JSZip();
    // フォルダ作成
    const folderName = "images";
    const folder = zip.folder(folderName);
  
    // フォルダ下に画像を格納
    images.forEach(image => {
      if (image.data && image.fileName) {
        folder.file(image.fileName, image.data)
      }
    });
  
    // zip を生成
    zip.generateAsync({ type: "blob" }).then(blob => {
  
      // ダウンロードリンクを 生成
      const link = document.createElement("a");
  
      // blob から URL を生成
      const dataUrl = URL.createObjectURL(blob);
      link.href = dataUrl;
      link.download = `${folderName}.zip`;
  
      // 設置/クリック/削除
      document.body.insertAdjacentElement("beforeEnd", link);
      link.click();
      link.remove();
  
      // オブジェクト URL の開放
      setTimeout(function() {
        window.URL.revokeObjectURL(dataUrl);
      }, 1000);
    });
  }

  async generateImagePromises(array) {
    return array.map(
      ({ url, fileName }) => new Promise((resolve) => {
        fetch(url)
          .then((value) => value.blob()
            .then((data) => resolve({ data, fileName })))
          .catch(() => resolve({ data: null, fileName: '' }));
      })
    );
  }

  async download_selected_images() {
    const selectedInputElms = $(".file_list").find('input').filter((_, elm) => elm['checked']);

    if (selectedInputElms.length === 0) {
      alert('ファイルが選択されていません');
      return;
    }

    const selectedUrlAndNameArray = selectedInputElms.map((_, elm) => {
      const url = $(elm).siblings('.file_image')[0].firstChild.dataset.originalSrc;
      const fileName = $(elm).parent()[0].dataset.contentName;

      return { url, fileName }
    }).get();

    const imagePromises = await this.generateImagePromises(selectedUrlAndNameArray)

    const images = await Promise.all(imagePromises);

    this.generateImagesZip(images);
  }

  
  async download_all_images() {
    const imageUrlAndNameArray = $('.file_list').children().map((_, elm) => {
      const url = $(elm).children('.file_image')[0].firstChild.dataset.originalSrc;
      const fileName = elm.dataset.contentName;

      return { url, fileName }
    }).get();

    const imagePromises = await this.generateImagePromises(imageUrlAndNameArray);

    const images = await Promise.all(imagePromises);
    
    this.generateImagesZip(images);
  }


  select_file_modal_open(target) {
    this.file_target = target;
    let library_select_modal = this.application.getControllerForElementAndIdentifier(
      this.library_select_modalTarget,
      "modal"
    );
    library_select_modal.open();
  }
  select_file_modal_close() {
    let library_select_modal = this.application.getControllerForElementAndIdentifier(
      this.library_select_modalTarget,
      "modal"
    );
    library_select_modal.close();
  }
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
  render_box_memos() { 
    let _this = this;
    $.ajax({
      type: "GET",
      url: "/client/api/v1/content_libraries/box_memo",
      success: function (data) {
        let existingTextareaElement = $("#content_box_memo-main").find("#box-memo");
        let existingDivElement = $("#content_box_memo-main").find("div");

        if (existingTextareaElement.length > 0 && existingDivElement.length > 0) {
          existingTextareaElement.text(data.memo);
          existingDivElement.html(_this.replace_url_with_link_and_newline(data.memo));
        } else {
          let html = `
            <div>${_this.replace_url_with_link_and_newline(data.memo)}</div>
            <textarea id="box-memo" style="display:none;">${data.memo}</textarea>
          `;
          $("#content_box_memo-main").append($(html));
        }
      },
      error: function (error) {
        alert(error);
      }
    })
  }
  modify_box_memo(element){
    $("#box-memo-update-button").css("display","none");
    $("#box-memo-cancel-button").css("display","block");
    $("#box-memo-save-button").css("display","block");
    $("#content_box_memo-main > div").css("display","none");
    $("#content_box_memo-main > textarea").css("display","block");
  }
  click_calcel_button() {
    $("#box-memo-update-button").css("display","block");
    $("#box-memo-cancel-button").css("display","none");
    $("#box-memo-save-button").css("display","none");
    $("#content_box_memo-main > div").css("display","block");
    $("#content_box_memo-main > textarea").css("display","none");
  }
  save_box_memo(element){
    let _this = this;
    $.ajax({
      type: "PATCH",
      url: "/client/api/v1/content_libraries/box_memo",
      data: {memo: $("#box-memo").val()},
      success: function (data) {
        _this.render_box_memos();
      },
      error: function (error) {
        alert(error);
      }
    })
    $("#box-memo-update-button").css("display","block");
    $("#box-memo-cancel-button").css("display","none");
    $("#box-memo-save-button").css("display","none");
    $("#content_box_memo-main > div").css("display","block");
    $("#content_box_memo-main > textarea").css("display","none");
  }
  replace_url_with_link_and_newline(text) {
    // 改行を<br>に変換し、URLを<a>タグで囲む
    return text.replace(/(https?:\/\/\S+)|\n/g, function(match, p1) {
      if (p1) {
        return `<a href="${p1}" target="_blank">${p1}</a>`;
      } else {
        return '<br>';
      }
    });
  }
}