import { Controller } from "stimulus"
// require('pdfjs-dist');
// import { PDFJS } from "pdfjs-dist/web/pdf_viewer";
// const PDFJS = require('pdfjs-dist');
// var pdfjsLib = require("pdfjs-dist/build/pdf.js");
// const pdfjsLib = window['pdfjs-dist/build/pdf'];

// root = window


export default class extends Controller {
  static targets = ["viewarea"]

  plan = 1

  initialize() {
    function pdf_url_add(opt) {
      let filepath = $(opt.element).attr("data-filepath")
      if (filepath != undefined) {
        return $("<span data-filepath='"+filepath+"'> " + opt.text + "</span>")
      } else {
        return $("<span>トレンド資料</span>")
      }
    }
    this.select2 = $('.select-searchble').select2(
      {
        width: '200',
        placeholder: "トレンド資料",
        templateSelection: pdf_url_add
      }
    );
    this.select2.on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    })
  }
  connect() {
    let _this = this;
    $.ajax({
      type: "GET",
      url: "/client/api/v1/statuses/trendpdf",
      success: (data) => {
        _this.plan = data["result"]["plan"]
        if (_this.plan == 1) {
          let title = "トレンド/豆知識"
          let text = ""
          _this.getControllerByIdentifier("modal").tutorial_data_append("schedule", data["result"]["tutorial_images"], title, text);
          _this.getControllerByIdentifier("modal").open();
          return;
        }
      }
    })
  }

  file_select(element) {
    let filepath = $("option:selected",$(element.target)).attr("data-filepath");
    if (filepath == undefined) return;
    let html = '<iframe id="viewarea" src="https://docs.google.com/viewer?&embedded=true&url='+ filepath+ '"></iframe>'
    
    if (window.chrome || typeof (window.mozInnerScreenX) != "undefined") {
      html = $(`<object id="viewarea" width="100%" height="100%" data="${filepath}" type="application/pdf">
                    <embed src="${filepath}" type="application/pdf" />
                </object>`);
    }

    $(this.viewareaTarget).html(html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}