import { Controller } from "stimulus"
import dayjs from 'dayjs';
import feather from 'feather-icons'

const MIN_CHECKER_COUNT = 2;
const MAX_CHECKER_COUNT = 4;

export default class extends Controller {
  static targets = [ "checklist_modal" , "form", "unchecked_modal"]

  unchecked_target = null;

  connect() {
    this.element[this.identifier] = this;
  }


  save() {
    let _this = this;
    let formdata = new FormData(this.formTarget);
    let caldate = dayjs(this.element.schedule.calendar_getDate);
    formdata.append('year_month', caldate.format("YYYYMM"));
    formdata.append('sns_url_id', this.element.schedule.selected_sns_url.id);
    let noticeController = this.getControllerByIdentifier("notice")

    $.ajax({
      url: "/client/api/v1/checklists/save",
      type: "patch",
      processData: false,
      contentType: false,
      data: formdata,
      success: function (data) {
        if (data["status"] == 200) {
          noticeController.success("更新しました");
          _this.append_account_direction();
          _this.exists_unchecked_checklist;
        } else {
          noticeController.warning("エラーが発生しました");
        }
      }
    })
    
  }

  async modal_open() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.checklist_modalTarget,
      "modal"
    );
    await this.append_account_direction();

    modalController.open();
  }

  append_account_direction(date = null) {
    let is_last_month_copy = date != null ? true : false;
    let caldate = date == null ? dayjs(this.element.schedule.calendar_getDate) : date;
    $(`.account_direction_wrapper`).children("table").each((index, el) => {
      $(el).find("tbody").children().remove();
    })
    $.ajax({
      type: "GET",
      url: "/client/api/v1/checklists",
      contentType: 'application/json',
      data: {sns_url_id: this.element.schedule.selected_sns_url.id, caldate: caldate.format("YYYY-MM-DD"), is_copy: is_last_month_copy},
      success: (data) => {
        if (!data["is_new"]) {
          $(".last_month_copy").css("color", "#888");
          $(".last_month_copy").data("copy", "0");
        } else {
          $(".last_month_copy").css("color", "#0099C6");
          $(".last_month_copy").data("copy", "1");
        }
        data["direction_checklists"].forEach((el, i) => {
          let tbody = $(`.account_direction_wrapper > .${el["checklist_type"]} > tbody`);
          tbody.append('<tr></tr>')
            .append(`<td>${el["name"]}</td>`)
            .append(`<td data-target="chcklist.account_direction_value" ${data.session_user_role === "master" ? 'data-action="click->checklist#input_text"' : ''}>
              <div class="text" ${data.session_user_role === "master" ? 'data-action="click->checklist#input_text_click"' : ''}>${el['description'] == null ? '' : el['description'].replace(/\r?\n/g, '<br>')}</div>
              <textarea name="directions[${el["id"]}]" rows="5" maxlength="${el["max_length"] == null ? "" : el["max_length"]}" data-action="focusout->checklist#change_text_focusout keydown->checklist#change_text_keydown" class="text-input" style="display:none">${el['description'] == null ? '' : el['description']}</textarea>
            </td>`);
        });
        $(".checklist .checker").remove();
        $(".checklist tbody").children().remove();
        data["checkers"].forEach((item, i) => {
          this.add_checker(null, item, i);
        });
        data["checklists"].forEach((item, i) => {
          this.add_checklist(null, item, (is_last_month_copy ? true : data["is_new"]), data.session_user_role);
        });

      }
    });

  }

  last_month_copy () {
    if ($(".last_month_copy").data("copy") == "1") {
      let last_month = dayjs(this.element.schedule.calendar_getDate).subtract(1, 'month');
      this.append_account_direction(last_month);
    }
  }
  input_text_click(element){
    $(element.target).css("display","none");
    $(element.target).next().css("display","block");
    $(element.target).next().focus();
  }
  input_text(element) {
    $(".text", element.target).css("display","none");
    $(".text-input", element.target).css("display","block");
    $(".text-input").focus();
  }

  change_text_focusout(element) {
    this.change_text(element);
  }
  change_text_keydown(element) {
    if (element.key == "Tab"){
      this.change_text(element);
    }
  }

  change_text(element) {
    $(".text-input", element.target).val($(".name", element.target).text());
    $(element.target).prev(".text").html($(element.target).val().replace(/\r?\n/g, '<br>')).css("display","block");
    $(element.target).css("display","none"); 
  }

  add_checklist(event , datas=null, is_new, session_user_role) {
    let tbody = $(".checklist > tbody");
    let thead = $(".checklist > thead");
    let thead_length = $("th" , thead).length;

    let user_role = session_user_role;
    if (user_role === undefined) {
      user_role = event.currentTarget.id;
    }

    let id = (datas == null || is_new)? "" : datas["id"];
    tbody.append(`<tr data-id="${id}"></tr>`);
    for(let i = 0; i < thead_length; i++){
      if (["owner", "checker"].includes($("th", thead)[i].classList.value)) {
        $("tr", tbody).last().append(`<td class="checker">
          <label class="control control--checkbox"" id="checker_check[${i+1}][${id}]" data-action="click->checklist#unchecked_confirm">
            <input type="checkbox" name="${id == "" ? "new_" : "" }checker_checks[${i+1}][${id}]" ${datas == null ? "" : datas["checker_checks"][i] ? "checked" : ""} data-is_checked=${datas == null ? "" : datas["checker_checks"][i] ? true : false}>
            <div class="control__indicator"></div>
          </label>
        </td>`);
      } else {
        let name = $(".checklist > thead th")[i].className;
        $("tr", tbody).last().append(`<td class="${$("th", thead)[i].classList.value}" >
          <div class="text" ${user_role === "master" ? 'data-action="click->checklist#input_text_click"' : ''}>${datas == null ? "" : $("th", thead)[i].classList.value == "item_names" ?  datas["name"] : datas["description"].replace(/\r?\n/g, '<br>')}</div>
          <textarea data-action="focusout->checklist#change_text_focusout keydown->checklist#change_text_keydown" value="${datas == null ? "" : $("th", thead)[i].classList.value == "item_names" ?  datas["name"] : datas["description"]}" name="${id == "" ? "new_" + name : name}[${id}]" style="display:none;" >${datas == null ? "" : $("th", thead)[i].classList.value == "item_names" ?  datas["name"] : datas["description"]}</textarea>
        </td>`);
      } 
    }
  }

  add_checker(event, checker=null, index) {
    let tbody = $(".checklist > tbody");
    let thead = $(".checklist > thead");
    let checker_count = $(".checker, .owner", thead).length;
    if (MAX_CHECKER_COUNT <= checker_count || (event == null && checker == null)) {
      return;
    }

    let checker_name = checker != null ?
      checker : 
        (index == 0) ? "作成者" : "確認者" + ((checker || index == 0) ? "" : checker_count)

    $(".item_names", thead).last().before(`<th class="checker" scope="col">
      <div class="name" data-action="click->checklist#checker_rename">
        <div class="text">${checker_name}</div>
        <input type="text" maxlength="10" name="checker_names[${checker_count + 1}]" value="${checker_name}" data-action="focusout->checklist#change_checker_name_focusout keydown->checklist#change_checker_name_keydown" style="display:none;" />
      </div>
      <div class="add_button" data-action="click->checklist#add_checker">
        <div class="add_icon plus_image">
          ${feather.icons.plus.toSvg({'stroke-width': 2, color: 'black' , width: 12, height: 12})}
        </div>
      </div>

      <div class="remove_button" data-action="click->checklist#remove_checker"> 
        <div class="remove_icon minus_image">
          ${feather.icons.minus.toSvg({'stroke-width': 2, color: 'black' , width: 12, height: 12})}
        </div>
      </div>
    </th>`);
    checker_count = $(".checker, .owner", thead).length;

    if (checker == null) {
      $("tr", tbody).each((index, el) => {
        let id = $(el).data("id");
        $(".checker", el).last().after(`<td class="checker">
          <label class="control control--checkbox" id="checker_0">
            <input type="checkbox" name="checker_checks[${checker_count}][${id}]" class="checker_checkbox">
            <div class="control__indicator"></div>
          </label>
        </td>`);
      });
    }

    checker_count = $(".checker, .owner", thead).length;
    $(".add_button",thead).appendTo("th.checker:last");
    $(".remove_button",thead).appendTo("th.checker:last");
    if (MAX_CHECKER_COUNT <= checker_count) {
      $(".add_button",thead).css("visibility","hidden");
    } else {
      $(".add_button",thead).css("visibility","visible");
    }
  }

  remove_checker() {
    let tbody = $(".checklist > tbody");
    let thead = $(".checklist > thead");
    let checker_count = $(".checker", thead).length;
    if (MIN_CHECKER_COUNT >= checker_count) {
      return;
    }
    $(".add_button",thead).appendTo($("th.checker").last().prev());
    $(".remove_button",thead).appendTo($("th.checker").last().prev());

    $(".checker", thead).last().remove();
    $("tr", tbody).each((index, el) => {
      $(".checker", el).last().remove();
    });
    checker_count = $(".checker", thead).length;
    if (MAX_CHECKER_COUNT <= checker_count) {
      $(".add_button",thead).css("visibility","hidden");
    } else {
      $(".add_button",thead).css("visibility","visible");
    }
  }
 
  checker_rename(element){
    $(element.target).css("display","none");
    $(element.target).next().css("display","block");
    $(element.target).next().focus();
  }

  change_checker_name_focusout(element) {
    this.change_checker_name(element);
  }

  change_checker_name_keydown(element) {
    if (element.key == "Tab"){
      this.change_checker_name(element);
    }
  }

  change_checker_name(element) {
    $(".text-input", element.target).val($(".name", element.target).text());
    $(element.target).prev(".text").text($(element.target).val()).css("display","block");
    $(element.target).css("display","none"); 
  }

  async exists_unchecked(){
    let caldate = dayjs(this.element.schedule.calendar_getDate);
    return await $.ajax({
      type: "GET",
      url: "/client/api/v1/checklists/exists_unchecked",
      contentType: 'application/json',
      data: {sns_url_id: this.element.schedule.selected_sns_url.id, caldate: caldate.format("YYYY-MM-DD")},
    });
  }

  modal_close() {
     let modalController = this.application.getControllerForElementAndIdentifier(
      this.checklist_modalTarget,
      "modal"
    );
    modalController.close();
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  unchecked_confirm(event) {
    if ($(event.target).prop("checked") == false && $(event.target).data("is_checked") == true) {
      let unchecked_modal = this.application.getControllerForElementAndIdentifier(this.unchecked_modalTarget, "modal");
      this.unchecked_target = event.target;
      unchecked_modal.open();
    }
  }

  unchecked_ok(){
    let unchecked_modal = this.application.getControllerForElementAndIdentifier(this.unchecked_modalTarget, "modal");
    unchecked_modal.close();
  }
  unchecked_cancel() {
    $(this.unchecked_target).prop("checked", true);
    let unchecked_modal = this.application.getControllerForElementAndIdentifier(this.unchecked_modalTarget, "modal");
    unchecked_modal.close();
  }

  get exists_unchecked_checklist() {
    return this.exists_unchecked().then((data) => {
      if (data["checklists_unchecked"]) {
        $(".checklist_open_modal_button").addClass("checklist_open_modal_button--active");
      } else {
        $(".checklist_open_modal_button").removeClass("checklist_open_modal_button--active");
      }

      if (data["modify_memos_unchecked"]) {
        $(".modify_memos_open_modal_button").addClass("modify_memos_open_modal_button--active");
      } else {
        $(".modify_memos_open_modal_button").removeClass("modify_memos_open_modal_button--active");
      }
    });
  }
}
