import { Controller } from "stimulus"
import Select2 from "select2"
import Chartkick from "chartkick"
import dayjs from 'dayjs';
export default class extends Controller {
  static targets = ["sns_url_id" ,"sub_sns_url_id","graphs", "type" , "from" ,"custom_date", "audience_date", "kind", "media_type", "modal" , "hashtag_search", "hashtag_search_label", "pdf_download", "audience_type_button"
  ]

  custom_from = '';
  custom_to = '';


  initialize() {
    let _this = this;

    function formatIcon(opt) {
      let img = $(opt.element).attr("data-picture")
      let social_media_image = $(opt.element).attr("data-social_media_image");
      if (social_media_image != undefined) {
        return $("<span><img src=\" " + social_media_image + " \" class=\"url_icon left\" style=\"width: 18px; height:18px;\"/><img src=\" " + img + " \" class=\"url_icon\" style=\"width: 18px; height:18px;\"/> " + opt.text + "</span>")
      }
    }
    
    $('.select-searchble').select2({
      tags: false,
      tokenSeparators: [',', ' '],
      width:385,
      templateResult: formatIcon,
      templateSelection: formatIcon
    });
    $(this.custom_dateTarget).daterangepicker({
      autoUpdateInput: false,
      locale: {
          applyLabel: 'OK'
      }
    }, (start, end, label) => {
      _this.custom_from = start.format('YYYY-MM-DD');
      _this.custom_to = end.format('YYYY-MM-DD');
      _this.fromTargets.forEach((el, i) => {
        el.checked = false;
      })
      _this.search();
    })

    $(this.audience_dateTarget).datetimepicker({
      timepicker:false,
      format:'Y-m-d',
      onSelectDate: (date, inst)=>{
        _this.audience_search(null, date)
      }
    })


     $(this.custom_dateTarget).on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
    });

    // $().datetimepicker({
    //   timepicker:false,
    //   format: 'Y/m/d'
    // });

  }
  connect() {
    this.element[this.identifier] = this;
    this.search();
  }

  modal_open() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    modalController.open();
  }
  modal_close() {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    modalController.close();
    this.search();
  }

  from_type_search() {
    this.custom_from = '';
    this.custom_to = '';
    $(this.custom_dateTarget).val('');
    this.search();
  }

  search() {
    let data = {};
    $("#sns_audience_graphs").css("display","none");
    $("#sns_hisotry_graph").css("display","block");
    $(".history_narrow").css("display","flex");
    $(".audience_narrow").css("display","none");

    if (this.type == "audience_chart") {
      this.audience_search(null, $(this.audience_dateTarget).val());
      return;
    }
    data = JSON.stringify({type: this.type, kind: this.kind,from: this.from, sns_url_ids: this.sns_url_ids, sub_sns_url_ids: this.sub_sns_url_ids})
    if (this.custom_from && this.custom_to) {
      data = JSON.stringify({type: this.type, kind: this.kind, custom_from: this.custom_from, custom_to: this.custom_to, sns_url_ids: this.sns_url_ids, sub_sns_url_ids: this.sub_sns_url_ids})
    }
    $.ajax({
      type: "POST",
      url: "/client/api/v1/histories?t=" + new Date().getTime(),
      contentType: 'application/json',
      data: data,
      success: (data) => {
        let main_sns_url = data["result"]["data"][0];
        this.main_social_media_type = main_sns_url.social_media_type; 
        if (main_sns_url.social_media_type == "tiktok") {
          $("#post_kind_2_label").css("display","none")
          $("#post_kind_4_label").css("display","none")
          $("#type_saved_label").css("display","none");
        }else {
          $("#post_kind_2_label").css("display","inline-block");
          $("#post_kind_4_label").css("display","inline-block");
          $("#type_saved_label").css("display","flex");
        }

        // Instagramのビジネスアカウントのみ表示
        if (main_sns_url.social_media_type == "instagram" && main_sns_url.is_business_account) {
          $("#sns_posts .filter_by_media_type").css("visibility","visible");
        } else {
          $("#sns_posts .filter_by_media_type").css("visibility","hidden");
        }

        $('#profile-link-button').attr("href", `${data.result.sns_url_link}`)

        if  (["followers_count", "reach_count", "impressions_count", "profile_views_count"].includes(this.type)) {
          var chart = new Chartkick.LineChart("sns_hisotry_graph");
        } else {
          var chart = new Chartkick.ColumnChart("sns_hisotry_graph");
        }
        let sub_sns_url_ids = data["result"]["data"].map( function (item) {
          if ($.inArray(item.sns_url_id, this.sns_url_ids) == -1) {
            return item.sns_url_id;
          }
        }, this)
        if (data["result"]["plan"] != 1) {
          this.getControllerByIdentifier("modal").benchmark_data_append("history",data["result"]["sub_sns_urls"], sub_sns_url_ids);
        }else {
          let title = "ベンチマーク比較他社分析機能"
          let text = ""
          this.getControllerByIdentifier("modal").tutorial_data_append("history", data["result"]["tutorial_images"], title, text);
        }
        if (data["result"]["hashtag"]) {
          $(this.hashtag_search_labelTarget).css("display","inherit")
          $(this.hashtag_searchTarget).attr("data-value", data["result"]["hashtag"]["id"])
          $(this.hashtag_search_labelTarget).text("#" + data["result"]["hashtag"]["name"])
        } else {
          $(this.hashtag_search_labelTarget).css("display","none")

        }
        $("input[name=from], input[name=kind]").prop("disabled",false)

        chart.options.library = data["result"]["library"];
        chart.updateData(data["result"]["data"]);

        this.disp_target_url_panel(data);
        this.getControllerByIdentifier("post").search();
      }
    })
  }

  hashtag_search(ele) {
    let hashtag_id = $(ele.target).attr("data-value")
    $("input[name=from], input[name=kind]").attr("disabled","disabled")
    $.ajax({
      type: "POST",
      url: "/client/api/v1/histories/hashtag?t=" + new Date().getTime(),
      data: {hashtag_id: hashtag_id},
      success: (data) => {
        var chart = new Chartkick.LineChart("sns_hisotry_graph");
        chart.options.library = data["result"]["library"]
        chart.updateData(data["result"]["data"]);
      }
    })
  }

  audience_search(event, input_date = null) {
    let date = dayjs().subtract(1, "d").format("YYYY-MM-DD");
    if (input_date != null && input_date != "") {
      date = dayjs(input_date).format("YYYY-MM-DD");
    }
    let data = {};
    $("#sns_hisotry_graph").css("display","none");
    $("#sns_audience_graphs").css("display","block");
    $(".history_narrow").css("display","none");
    $(".audience_narrow").css("display","flex");
    this.audience_locale_type_display();

    data = JSON.stringify({date: String(date), kind: this.kind,from: this.from, sns_url_ids: this.sns_url_ids, sub_sns_url_ids: this.sub_sns_url_ids})
    $.ajax({
      type: "POST",
      url: "/client/api/v1/histories/audience_insights?t=" + new Date().getTime(),
      contentType: 'application/json',
      data: data,
      success: (data) => {
        let gender_chart = new Chartkick.PieChart("audience_gender_chart");
        let age_chart = new Chartkick.BarChart("audience_age_chart");
        let country_chart = new Chartkick.ColumnChart("audience_country_chart");
        let city_chart = new Chartkick.ColumnChart("audience_city_chart");
        let options = {colors: data["colors"],legend: true,
          library: {
            xAxis: {
              reversed: true
            },
            plotOptions:{
              column: {
                pointWidth: 40,
                groupPadding: 1,
              },
            },
            legend: { y: 20 },
            messages: {empty: "No data"} 
          }
        };
        country_chart.options = city_chart.options = options;
        gender_chart.options  = {...options, ...{
          width: "50%",
          library: {
            legend: {
              enabled: true,
              align: 'center',
              verticalAlign: 'bottom'
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false, 
                },
                showInLegend: true,
              },
            },
          }
        }};
        age_chart.options = {...options, ...{
          stacked: true,
          library: { yAxis: { reversedStacks: false } }
        }};

        country_chart.updateData(data["data"]["audience_country"]["data"]);
        city_chart.updateData(data["data"]["audience_city"]["data"]);
        gender_chart.updateData(data["data"]["audience_gender"]["data"]);
        age_chart.updateData(data["data"]["audience_age"]["data"]);

      }
    })
  }

  audience_locale_type_display() {
    if (this.audience_type_button_checked_value == "city") {
      $("#audience_country_chart").css("display","none");
      $("#audience_city_chart").css("display","block");
    } else {
      $("#audience_country_chart").css("display","block");
      $("#audience_city_chart").css("display","none");
    }
  }
  
  disp_target_url_panel(data) {
    if (data["result"]["data"].length <= 0) return;
    $(".history_url_infos").empty();
    data["result"]["data"].forEach((el, i) => {
      let html = $(this.info_template());
      $(".name", html).append(el["name"]);
      $(".name",html).attr("data-id",el["sns_url_id"]);
      html.css("border-left", "solid 10px " + el["color"])
      $(".history_url_infos").append(html)
    })
  }

  info_template(){
    let string = '<div class="url_info">';
    string += ' <div class="name" data-id=""></div>'
    string += '</div>'
    return string
  }

  onclick_check(ele) {
    if ($(this.sub_sns_url_idTargets).filter(":checked").length > 5) {
      let noticeController = this.getControllerByIdentifier("notice");
      noticeController.warning("比較は5件までです。");
      ele.preventDefault();
    }
  }

  pdf_param() {
    let post_kind = $('input:radio[name="post_kind"]:checked').val()
    let param_string = "?sns_url_id=" + this.sns_url_ids + "&kind=" + this.kind + "&from=" + this.from + "&post_kind=" + post_kind + "&type=" + this.type + "&custom_from=" + this.custom_from +"&custom_to=" + this.custom_to;
    if ("audience_chart" == this.type) {
      param_string += "&date=" + $(this.audience_dateTarget).val() + "&audience_locale_type=" + this.audience_type_button_checked_value;
    }
    return param_string
    // {type: this.type, kind: this.kind, from: this.from, sns_url_ids: this.sns_url_ids,}
  }

  pdf_download_click(ele) {
    let _this = this;
    $(this.pdf_downloadTarget).css("pointer-events", "none");
    setTimeout(function () { 
      $(_this.pdf_downloadTarget).css("pointer-events", "auto");
    },10000)
    open("/client/analysis/createpdf" + this.pdf_param(), "_blank")
    return false;
  }

  custom_from_open() {
    // console.log(this.custom_fromTarget)
    // $(this.custom_date_fromTarget).datetimepicker({})
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  get urls() {
    let urls = {}
    this.sns_url_idTargets.forEach((el, i ) => {
      if ($(el).text() != undefined) {
        urls[el.value] = $(el).text()
      }
    });
    this.sub_sns_url_idTargets.forEach((el, i ) => {
      if (el.checked) {
        urls[el.value] = $(el).attr("data-name");
      }
    })
    return urls
  }

  get type() {
    let type = ""
    this.typeTargets.forEach((el, i) => {
      if (el.value != undefined) {
        type = el.value
      }
    })
    return type
  }

  get kind() {
    let kind = ""
    this.kindTargets.forEach((el, i) => {
      if (el.checked) {
        kind = el.value
      }
    })
    return kind
  }
  
  get from() {
    let from = ""
    this.fromTargets.forEach((el, i) => {
      if (el.checked) {
        from = el.value
      }
    })
    return from
  }

  get sns_url_ids() {
    let url_ids = []
    this.sns_url_idTargets.forEach((el, i ) => {
      if (el.value != undefined) {
        url_ids.push(parseInt(el.value))
      }
    })
    return url_ids
  }

  get sub_sns_url_ids() {
    let url_ids = []
    this.sub_sns_url_idTargets.forEach((el, i ) => {
      if (el.checked) {
        url_ids.push(parseInt(el.value))
      }
    })
    return url_ids
  }

  get audience_type_button_checked_value() {
    let value = ""
    return $("input[name='type_audience']:checked").val();
  }
}
